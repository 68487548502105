import BucketsPricingModelService from "./buckets-pricing-model-service";
import PricingVersions from "/constants/pricing-versions";
import { shouldSeeV10PricingVersion } from "services/ab-tests/monetization-ab-tests-service";

export const getPricingModelService = (pricingVersion) => {
  const pricingModelsModelsMapping = {
    [PricingVersions.VERSION_9]: BucketsPricingModelService
  };

  const PricingModelService =
    pricingModelsModelsMapping[pricingVersion] || pricingModelsModelsMapping[PricingVersions.DEFAULT_VERSION];
  return new PricingModelService();
};

export const getPricingVersion = ({ forcePricing, cookies = {}, abTests = [] } = {}) => {
  const shouldSeeV10 = shouldSeeV10PricingVersion({ cookies, abTests, forcePricing });
  return shouldSeeV10 ? PricingVersions.VERSION_10 : PricingVersions.DEFAULT_VERSION;
};
