import {
  HOMEPAGE_ACCOUNT_CREATION_ITEM_RESOURCE_CREDIT_COOKIE_NAME,
  MONDAY_FREE_TIER_ACCOUNT_CREATION_ITEM_RESOURCE_CREDIT_COOKIE_NAME
} from "constants/cookies";
import { ITEM_RESOURCE_CREDIT_FOR_FREE_TIER } from "constants/free-tier";
import { isRunningInClient } from "services/dom-utils-service";
import { setCookie } from "services/cookies-service";

export const getItemResourceCreditCount = (abTests, cookies) => {
  const forcedItemResourceCreditFromPlatform =
    cookies[MONDAY_FREE_TIER_ACCOUNT_CREATION_ITEM_RESOURCE_CREDIT_COOKIE_NAME];
  if (forcedItemResourceCreditFromPlatform) return parseInt(forcedItemResourceCreditFromPlatform);

  const resourceCreditAmount = ITEM_RESOURCE_CREDIT_FOR_FREE_TIER;
  if (isRunningInClient()) setCookie(HOMEPAGE_ACCOUNT_CREATION_ITEM_RESOURCE_CREDIT_COOKIE_NAME, resourceCreditAmount);

  return resourceCreditAmount;
};
