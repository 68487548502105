import { TIERS } from "../../../pricing";
import {
  FREE_TIER_VALUE_FEATURES,
  BASIC_TIER_VALUE_FEATURES,
  STANDARD_TIER_VALUE_FEATURES,
  PRO_TIER_VALUE_FEATURES,
  STUDENT_PLAN_VALUE_FEATURES,
  ENTERPRISE_TIER_VALUE_FEATURES
} from "../features/value_feature_collections";
import { shouldHideBasic, getFreeTierFeaturesByAbTest } from "services/ab-tests/monetization-ab-tests-service";

export const DEFAULT_VALUE_FEATURES_PER_TIER = ({ hasStudentPlan, hasFreeTier, abTests, cookies, forcePricing }) => {
  const withoutBasic = shouldHideBasic({ abTests, cookies, forcePricing });
  return {
    [TIERS.FREE]: hasStudentPlan ? STUDENT_PLAN_VALUE_FEATURES : getFreeTierFeaturesByAbTest({ abTests, cookies, forcePricing }),
    [TIERS.BASIC]: BASIC_TIER_VALUE_FEATURES({ hasFreeTier }),
    [TIERS.STANDARD]: STANDARD_TIER_VALUE_FEATURES({ withoutBasic, hasFreeTier }),
    [TIERS.PRO]: PRO_TIER_VALUE_FEATURES,
    [TIERS.ENTERPRISE]: ENTERPRISE_TIER_VALUE_FEATURES
  };
};

export const getDefaultValueFeatures = ({ hasStudentPlan, hasFreeTier, abTests, cookies, forcePricing }) => {
  return DEFAULT_VALUE_FEATURES_PER_TIER({ hasStudentPlan, hasFreeTier, abTests, cookies, forcePricing });
};
