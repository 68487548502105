import isEmpty from "lodash/isEmpty";
import { isInClusterTest } from "./pricing-page/clusterized-pricing-page-service";

const getDiscountFromClusterConfig = (clusterConfig, abTests, cookies, forcePricing) => {
  const discount = clusterConfig?.payments_view?.pricing?.discount;
  if (!isEmpty(discount) && isInClusterTest(clusterConfig, { abTests, cookies, forcePricing })) {
    return discount;
  }
};

const getFreeUsersFromClusterConfig = (clusterConfig, abTests, cookies, forcePricing) => {
  const freeOptions = clusterConfig?.payments_view?.pricing?.users_count_options?.free_options;
  if (!isEmpty(freeOptions) && isInClusterTest(clusterConfig, { abTests, cookies, forcePricing })) {
    return freeOptions;
  }
};

export const getMaxFreeUsersFromClusterConfig = (clusterConfig, abTests, cookies, forcePricing) => {
  const freeOptions = getFreeUsersFromClusterConfig(clusterConfig, abTests, cookies, forcePricing);
  if (freeOptions?.length > 0) {
    return Math.max(...freeOptions);
  }
};

export const applyDiscountIfNeeded = (price, tier, period, clusterConfig, abTests, cookies, forcePricing) => {
  const discount = getDiscountFromClusterConfig(clusterConfig, abTests, cookies, forcePricing);
  if (isEmpty(discount)) return price;

  const { value, valid_tiers: validTiers, valid_periods: validPeriods } = discount;

  if (!value) return price;

  const isValidTier = isEmpty(validTiers) || validTiers.includes(tier);
  const isValidPeriod = isEmpty(validPeriods) || validPeriods.includes(period);
  const isDiscountValidForPlan = isValidTier && isValidPeriod;

  if (!isDiscountValidForPlan) return price;
  return price * (1 - value);
};

export const applyFreeUsersIfNeeded = (price, initialUsersCount, clusterConfig, abTests, cookies, forcePricing) => {
  let usersCount = initialUsersCount;
  const maxFreeUsers = getMaxFreeUsersFromClusterConfig(clusterConfig, abTests, cookies, forcePricing);
  if (maxFreeUsers > 0) {
    usersCount = initialUsersCount - maxFreeUsers;
  }
  return Math.max(price * usersCount, 0);
};

export const shouldHideCrossedOutPrice = (clusterConfig, abTests, cookies, forcePricing) => {
  const discount = clusterConfig?.payments_view?.pricing?.discount;
  if (!isEmpty(discount) && isInClusterTest(clusterConfig, { abTests, cookies, forcePricing })) {
    return discount.hide_crossed_out_discount;
  }
  return false;
};
