import { getAbTestVariantNew } from "services/new-ab-tests/new-ab-tests-service";
import {
  HOMEPAGE_FREE_TIER_2022_ABC_TEST,
  HOMEPAGE_FREE_TIER_NAME_FREE_AB_TEST,
  HOMEPAGE_HIDE_BASIC_AB_TEST,
  HOMEPAGE_V10_PRICING_VERSION_AB_TEST
} from "constants/new-ab-tests";
import {
  PLATFORM_FREE_TIER_NAME_FREE_COOKIE,
  PLATFORM_FREE_TIER_VERSION_COOKIE,
  PLATFORM_HIDE_BASIC_COOKIE,
  PLATFORM_PRICING_VERSION_COOKIE
} from "constants/cookies";
import PricingVersions from "/constants/pricing-versions";
import { FREE_TIER_2021_SEATS_LIMIT, FREE_TIER_2022_SEATS_LIMIT } from "constants/pricing";
import { FREE_TIER_VALUE_FEATURES } from "constants/pricing/pricing-page/features/value_feature_collections";
import { PRICING_SUB_TITLE_V2_AB_TEST, PURCHASE_NOW_AB_TEST } from "constants/monetization-ab-tests.ts";
import { PRICING_SUB_TITLE_V2 } from "constants/force-pricing.ts";
import { isMobile } from "/utils/mobile";
import * as VALUE_FEATURES from "constants/pricing/pricing-page/features/value_features.js";

export const shouldHideBasic = ({ abTests, forcePricing, cookies, hasStudentPlan }) => {
  if (hasStudentPlan) return true; // First we check if we're in student plan mode - in this mode we don't show the basic tier

  const { [PLATFORM_HIDE_BASIC_COOKIE]: platformCookieValue } = cookies || {}; // The visitor might have came from the platform before, so we check if we hide basic in the platform
  if (platformCookieValue) return platformCookieValue === "true";
  if (forcePricing === "without_basic") return true; // We want to test it by ourselves so we use force_pricing query param.

  return (
    getAbTestVariantNew(abTests, HOMEPAGE_HIDE_BASIC_AB_TEST.test_name) ===
    HOMEPAGE_HIDE_BASIC_AB_TEST.new_variation_name
  );
};

export const shouldSeeProductsPricingPage = ({
  forcePricing,
}) => {
  return forcePricing === "products";
};

export const shouldSeeV10PricingVersion = ({ cookies = {}, abTests = [], forcePricing }) => {
  if (forcePricing === "v10") return true;

  const platformCookie = cookies[PLATFORM_PRICING_VERSION_COOKIE];
  if (platformCookie) return platformCookie === PricingVersions.VERSION_10;

  const abTestVariant = getAbTestVariantNew(abTests, HOMEPAGE_V10_PRICING_VERSION_AB_TEST.test_name);
  return abTestVariant === HOMEPAGE_V10_PRICING_VERSION_AB_TEST.new_variation_name;
};

const getFreeTierVersion = (cookies, abTests, forcePricing) => {
  const platformCookie = cookies[PLATFORM_FREE_TIER_VERSION_COOKIE];
  const forceFreeTierVersion = forcePricing || platformCookie;

  const variantToVersion = {
    [HOMEPAGE_FREE_TIER_2022_ABC_TEST.new_variation_name_b]: "2022_a",
    [HOMEPAGE_FREE_TIER_2022_ABC_TEST.new_variation_name_c]: "2022_b"
  };

  const freeTierVersion =
    forceFreeTierVersion ||
    variantToVersion[HOMEPAGE_FREE_TIER_2022_ABC_TEST.new_variation_name_b];
  return freeTierVersion;
};

export const isFreeTierNameFree = ({ cookies = {}, abTests = [], forcePricing }) => {
  if (forcePricing === "free_tier_name_free") return true;

  const freeTierVersion = getFreeTierVersion(cookies, abTests, forcePricing);
  if (freeTierVersion === "2022_b") return true;

  const platformCookie = cookies[PLATFORM_FREE_TIER_NAME_FREE_COOKIE];
  if (platformCookie) return platformCookie === "true";

  const abTestVariant = getAbTestVariantNew(abTests, HOMEPAGE_FREE_TIER_NAME_FREE_AB_TEST.test_name);
  return abTestVariant === HOMEPAGE_FREE_TIER_NAME_FREE_AB_TEST.new_variation_name;
};

export const getFreeTierFeaturesByAbTest = ({ cookies = {}, abTests = [], forcePricing }) => {
  const freeTierVersion = getFreeTierVersion(cookies, abTests, forcePricing);

  switch (freeTierVersion) {
    case "2022_a":
      return [
        VALUE_FEATURES.UP_TO_THREE_BOARDS,
        VALUE_FEATURES.UNLIMITED_DOCS,
        VALUE_FEATURES.OVER_200_TEMPLATES,
        VALUE_FEATURES.COLUMN_TYPES,
        VALUE_FEATURES.UP_TO_X_TEAM_MEMBERS(FREE_TIER_2021_SEATS_LIMIT),
        VALUE_FEATURES.MOBILE
      ];
    case "2022_b":
      return [
        VALUE_FEATURES.UP_TO_THREE_BOARDS,
        VALUE_FEATURES.UNLIMITED_DOCS,
        VALUE_FEATURES.OVER_200_TEMPLATES,
        VALUE_FEATURES.COLUMN_TYPES,
        VALUE_FEATURES.UP_TO_X_TEAM_MEMBERS(FREE_TIER_2022_SEATS_LIMIT),
        VALUE_FEATURES.MOBILE
      ];
    default:
      return FREE_TIER_VALUE_FEATURES;
  }
};

export const getFreeTierSeatsCountByAbTest = ({ cookies = {}, abTests = [], forcePricing }) => {
  const freeTierVersion = getFreeTierVersion(cookies, abTests, forcePricing);

  switch (freeTierVersion) {
    case "2022_a":
      return FREE_TIER_2021_SEATS_LIMIT;
    case "2022_b":
      return FREE_TIER_2022_SEATS_LIMIT;
    default:
      return FREE_TIER_2021_SEATS_LIMIT;
  }
};

export const getIsUnlimitedBoards = ({ cookies = {}, abTests = [], forcePricing }) => {
  const freeTierVersion = getFreeTierVersion(cookies, abTests, forcePricing);

  switch (freeTierVersion) {
    case "2022_a":
      return false;
    case "2022_b":
      return false;
    default:
      return true;
  }
};

export const shouldShowPurchaseNow = ({ abTests = [], forcePricing }) => {
  if (forcePricing === "purchase_now") return true;
  if (isMobile()) return false;
  const abTestVariant = getAbTestVariantNew(abTests, PURCHASE_NOW_AB_TEST.test_name, true);
  return abTestVariant === PURCHASE_NOW_AB_TEST.new_variation_name;
};

export const shouldShowPricingSubTitleV2 = ({ abTests = [], forcePricing }) => {
  if (forcePricing === PRICING_SUB_TITLE_V2) return true;
  const abTestVariant = getAbTestVariantNew(abTests, PRICING_SUB_TITLE_V2_AB_TEST.test_name, true);
  return abTestVariant === PRICING_SUB_TITLE_V2_AB_TEST.new_variation_name;
};
