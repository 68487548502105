import isString from "lodash/isString";
import compact from "lodash/compact";
import { TIERS } from "constants/pricing";
import ALL_SECTIONS from "constants/pricing/pricing-page/sections/all_sections";
import * as FEATURES from "constants/pricing/pricing-page/features/features";
import { PRICING_PAGE_MISSING_CLUSTER_INFO_ENCOUNTERED } from "constants/bigbrain-event-types";
import { trackEvent } from "services/bigbrain-service";
import {
  runForEachTier,
  EMPTY_ARRAY,
  getFirstTranslatedString,
  applyOverrides
} from "./clusterized-pricing-page-helper-service";

const DEFAULT_FEATURE_KEYS = ["name", "description"];
const DEFAULT_SECTION_KEYS = ["sectionName"];

const isFeatureValid = (feature) => {
  for (let key of DEFAULT_FEATURE_KEYS) {
    if (!isString(feature[key]) || feature[key].length === 0) {
      return false;
    }
  }
  if (!feature.tiers || Object.keys(feature.tiers).length === 0) return false;
  return true;
};

const isSectionValid = (section) => {
  for (let key of DEFAULT_SECTION_KEYS) {
    if (!isString(section[key]) || section[key].length === 0) {
      return false;
    }
  }
  if (!section.features || section.features.length === 0) return false;
  return true;
};

const convertStringToFeatureTierValue = (stringValue) => {
  if (!stringValue) return false;
  if (stringValue == "true") return true;
  return getFirstTranslatedString(EMPTY_ARRAY.concat(stringValue));
};

const getFeatureTierValue = (tier, tiersObject, feature) => {
  if (tiersObject && tiersObject[tier]) {
    return { value: convertStringToFeatureTierValue(tiersObject[tier]) };
  } else if (tier === TIERS.FREE) {
    // free tier gets, by default, the value of the basic tier, unless defined otherwise.
    return getFeatureTierValue(TIERS.BASIC, tiersObject, feature);
  } else if (!feature?.tiers[tier]) {
    // no value defined for this tier, and it's a new feature
    return { value: false };
  }
  return feature.tiers[tier];
};

const generateDefaultTiersObject = () => {
  const tiersObject = {};
  runForEachTier((tier) => {
    tiersObject[tier] = { value: false };
  });
  return tiersObject;
};

/**
 * Retrieves a feature's default values by that feature's name, and returns those values as a feature object.
 * @param {*} featureName - assumed to be uppercased, to match the constant.
 * @param {*} abTests - for features whose default value depends on A/B test variants.
 * @param {*} cookies - for features whose default value depends on cookies.
 */
const getExistingFeature = (featureName, { abTests, cookies, hasStudentPlan, hasFreeTier }) => {
  if (typeof FEATURES[featureName] === "function") {
    return FEATURES[featureName]({ abTests, cookies, hasStudentPlan, hasFreeTier });
  }
  const feature = FEATURES[featureName];
  return feature ? { ...feature } : null;
};

const convertFeature = (featureNameOrObject, { abTests, cookies, hasStudentPlan, hasFreeTier }) => {
  if (isString(featureNameOrObject)) {
    // shortcut - if a string is provided and it matches an existing code feature, it will be used.
    const feature = getExistingFeature(featureNameOrObject, {
      abTests,
      cookies,
      hasFreeTier,
      hasStudentPlan
    });
    if (!feature) {
      trackEvent(PRICING_PAGE_MISSING_CLUSTER_INFO_ENCOUNTERED, {
        kind: "name",
        placement: "features",
        data: featureNameOrObject
      });
      return null;
    }
    return feature;
  }

  // got an object - verifying that it has a name (either an existing feature's or an override)
  const { feature, featureName, overrides } = featureNameOrObject; // 'featureName' for backwards compatibility - TODO remove after fixing existing configs.
  if (!feature && !featureName && !overrides?.name) {
    trackEvent(PRICING_PAGE_MISSING_CLUSTER_INFO_ENCOUNTERED, {
      kind: "object",
      placement: "features",
      data: featureNameOrObject
    });
    return null; // must have either an existing feature's name, or a new one.
  }

  // overriding name and description:
  const existingFeature =
    getExistingFeature(feature, { abTests, cookies, hasStudentPlan, hasFreeTier }) ||
    getExistingFeature(featureName, { abTests, cookies, hasFreeTier, hasStudentPlan }) ||
    {}; // using an existing feature if one matches the name. Features can be identified either by 'feature' or 'featureName'.
  const featureWithOverrides = applyOverrides(existingFeature, featureNameOrObject?.overrides, DEFAULT_FEATURE_KEYS);

  // overriding tiers:
  const { tiers } = overrides || {};
  if (!featureWithOverrides.tiers) {
    // new feature - no tiers defined
    featureWithOverrides.tiers = generateDefaultTiersObject();
  }
  runForEachTier((tier) => {
    featureWithOverrides.tiers[tier] = getFeatureTierValue(tier, tiers, featureWithOverrides);
  });

  if (!isFeatureValid(featureWithOverrides)) return null;
  return featureWithOverrides;
};

/**
 * Retrieves a section's default values (name and features list) by that section's name, and returns those as a
 * section object.
 * @param {*} sectionName
 * @param {*} abTests - in case the section data depends on AB tests.
 * @param {*} cookies - in case the section data depends on cookies.
 */
const getExistingSection = (sectionName, { abTests, cookies, hasStudentPlan, hasFreeTier }) => {
  let originalSection;
  if (typeof ALL_SECTIONS[sectionName] === "function") {
    originalSection = ALL_SECTIONS[sectionName]({ abTests, cookies, hasStudentPlan, hasFreeTier });
  } else {
    originalSection = ALL_SECTIONS[sectionName];
  }
  return originalSection ? { ...originalSection } : null;
};

const convertSection = (sectionNameOrObject, { abTests, cookies, hasStudentPlan, hasFreeTier }) => {
  if (isString(sectionNameOrObject)) {
    // shortcut - if a string is provided and it matches an existing code section, that section will be used.
    const section = getExistingSection(sectionNameOrObject, {
      abTests,
      cookies,
      hasFreeTier,
      hasStudentPlan
    });
    if (!section) {
      trackEvent(PRICING_PAGE_MISSING_CLUSTER_INFO_ENCOUNTERED, {
        kind: "name",
        placement: "sections",
        data: sectionNameOrObject
      });
      return null;
    }
    return section;
  }

  // creating a section object using the given input
  const { section, sectionName, overrides } = sectionNameOrObject; // 'sectionName' for backwards compatibility - TODO remove after fixing existing configs
  if (!section && !sectionName && !overrides?.sectionName) {
    trackEvent(PRICING_PAGE_MISSING_CLUSTER_INFO_ENCOUNTERED, {
      placement: "sections",
      kind: "object",
      data: sectionNameOrObject
    });
    return null; // must have an existing section's name, or a new one.
  }

  const existingSection =
    getExistingSection(section, { abTests, cookies, hasStudentPlan, hasFreeTier }) ||
    getExistingSection(sectionName, { abTests, cookies, hasStudentPlan, hasFreeTier }) ||
    {}; // using an existing section if one matches the name.
  const sectionWithOverrides = applyOverrides(existingSection, overrides, DEFAULT_SECTION_KEYS);

  if (!sectionWithOverrides.features) {
    sectionWithOverrides.features = [];
  }
  if (overrides?.features) {
    sectionWithOverrides.features = compact(
      overrides.features.map((feature) => convertFeature(feature, { abTests, cookies, hasStudentPlan, hasFreeTier }))
    );
  }

  if (!isSectionValid(sectionWithOverrides)) return null;
  return sectionWithOverrides;
};

export const convertSectionToPricingPageSection = (value, { abTests, cookies, hasStudentPlan, hasFreeTier }) => {
  return convertSection(value, { abTests, cookies, hasStudentPlan, hasFreeTier });
};
