const VERSION_9 = "9";
const VERSION_10 = "10";

const DEFAULT_VERSION = VERSION_9;
const ALLOWED_VERSIONS = [VERSION_9, VERSION_10];

module.exports = {
  VERSION_9,
  VERSION_10,
  DEFAULT_VERSION,
  ALLOWED_VERSIONS
};
