import isString from "lodash/isString";
import { TIERS } from "constants/pricing";
import englishTranslations from "services/localization/keys/all.json";

export const isObject = (value) => typeof value === "object" && value !== null;

export const runForEachTier = (callback) => {
  Object.keys(TIERS).forEach((tierKey) => callback(TIERS[tierKey]));
};

export const EMPTY_ARRAY = []; // used in multiple places.

export const getFirstTranslatedString = (stringsArray) => {
  if (!stringsArray || stringsArray.length === 0) return "";
  for (let key of stringsArray) {
    if (englishTranslations[key]) {
      return key; // will be translated by the component.
    }
  }
  // couldn't find a translation for this value - treating it as a regular string, possibly enclosed in an array.
  if (Array.isArray(stringsArray)) {
    return `${stringsArray[0]}`;
  }
  return `${stringsArray}`;
};

export const applyOverrides = (originalObject, overridesObject, keys) => {
  const returnedObject = { ...originalObject };
  for (let key of keys) {
    const overrideValue = overridesObject?.[key];
    if (isString(overrideValue) || Array.isArray(overrideValue)) {
      returnedObject[key] = getFirstTranslatedString(overridesObject[key]);
    }
  }
  return returnedObject;
};
