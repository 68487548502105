interface AbTest {
  test_name: string;
  new_variation_name: string;
}

export const PURCHASE_NOW_AB_TEST: AbTest = {
  test_name: "purchase_now_ab_test",
  new_variation_name: "new_with_purchase_now_button"
};

export const PRICING_SUB_TITLE_V2_AB_TEST: AbTest = {
  test_name: "pricing_page_sub_title_v2_ab_test",
  new_variation_name: "new_pricing_with_sub_title_v2"
};
