import isEmpty from "lodash/isEmpty";
import { isInClusterTest } from "../pricing-page/clusterized-pricing-page-service";
import BasePricingModelService from "./base-pricing-model-service";
import PricingVersions from "constants/pricing-versions";
import { MAX_TEAM_SIZE } from "constants/pricing";
import { productPrices } from "services/pricing/product-prices";

export default class BucketsPricingModelService extends BasePricingModelService {
  shouldShowUserOptions() {
    return true;
  }

  getClusterUserOptions(options = {}) {
    const { clusterConfig, abTests, forcePricing, cookies } = options;
    const users_count_options = clusterConfig?.payments_view?.pricing?.users_count_options;
    if (!isEmpty(clusterConfig) && isInClusterTest(clusterConfig, { abTests, forcePricing, cookies })) {
      return users_count_options?.all_options;
    }
  }

  getDefaultUserOption(options = {}) {
    const clusterUserOptions = this.getClusterUserOptions(options);
    if (clusterUserOptions?.length > 0) return clusterUserOptions[0];
    return "3";
  }

  getUserOptions(options = {}) {
    const clusterUserOptions = this.getClusterUserOptions(options);
    if (clusterUserOptions?.length > 0) return clusterUserOptions;

    return ["3", "5", "10", "15", "20", "25", "30", "40", "50", "100", MAX_TEAM_SIZE];
  }

  getPrices({ pricingVersion = PricingVersions.DEFAULT_VERSION }) {
    return productPrices.core[pricingVersion];
  }
}
