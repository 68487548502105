import isString from "lodash/isString";
import compact from "lodash/compact";
import { TIERS } from "constants/pricing";
import { getDefaultTierDescriptions } from "constants/pricing/pricing-page/tier-descriptions";
import { setCookieIfNeeded } from "services/cookies-service";
import { getAbTestVariantNew } from "services/new-ab-tests/new-ab-tests-service";
import { convertSectionToPricingPageSection } from "./clusterization/clusterized-pricing-page-features-service";
import { getClusterizedValueFeatures } from "./clusterization/clusterized-pricing-page-value-features-service";
import { isObject, runForEachTier } from "./clusterization/clusterized-pricing-page-helper-service";

export const isInClusterTest = (clusterConfig, { abTests, cookies, forcePricing }) => {
  return true;
  return false;
  if (!clusterConfig?.payments_view) return false; // no payments config -> no AB test
  const { abTestConfig } = clusterConfig?.payments_view;
  if (!abTestConfig?.homepage) return true; // cluster pricing config is active by default if there isn't a homepage test
  const {
    disableTest,
    testName,
    newVariationName,
    sendBigBrainEvent,
    syncCookieName,
    syncCookieValue,
    homepageSyncCookieName,
    forcePricing: configForcePricing
  } = abTestConfig.homepage;
  // checking: the test was manually disabled for now
  if (disableTest === "true" || disableTest === true) {
    return true;
  }
  // checking: 'forcePricing' value was passed
  if (configForcePricing && configForcePricing === forcePricing) {
    return true;
  }
  // checking: the user already received a platform variant
  // TODO return after caching issues have been fully cleared
  if (cookies) {
    const matchingPlatformCookie = cookies[syncCookieName];
    if (matchingPlatformCookie && syncCookieValue && matchingPlatformCookie === syncCookieValue) {
      // otherwise, undefined === undefined.
      return true; // achieved via platform <--> homepage test synchronization.
    }
  }
  const abTestVariant = getAbTestVariantNew(abTests, testName, sendBigBrainEvent);
  // if we want to synchronize the homepage's AB test result to the platform:
  // TODO return after caching issues have been fully cleared
  if (isString(homepageSyncCookieName) && isString(abTestVariant)) {
    setCookieIfNeeded(homepageSyncCookieName, abTestVariant);
  }
  return abTestVariant === newVariationName;
};

// Expected input:
// { sections:
//  ([ESSENTIALS, ...] or [{sectionName: 'ESSENTIALS', overrides: ...}])
// }
export const getPricingPageSectionsFromClusterConfig = (
  pricingConfig,
  { abTests = {}, cookies = {}, hasStudentPlan = false, hasFreeTier = false }
) => {
  if (!isObject(pricingConfig) || !Array.isArray(pricingConfig.sections)) return;
  const { sections } = pricingConfig;
  return compact(
    sections.map((section) =>
      convertSectionToPricingPageSection(section, { abTests, cookies, hasStudentPlan, hasFreeTier })
    )
  );
};

export const getTierDescriptionsFromClusterConfig = (
  pricingConfig,
  clusterId,
  { hasStudentPlan, cookies, abTests, forcePricing }
) => {
  if (!isObject(pricingConfig) || !isObject(pricingConfig.tier_descriptions)) return;
  // getting default tier descriptions
  const { tier_descriptions } = pricingConfig;
  const returnedDescriptions = { ...getDefaultTierDescriptions({ hasStudentPlan, cookies, abTests, forcePricing }) };
  // overriding them if any exist
  if (isObject(tier_descriptions)) {
    runForEachTier((tier) => {
      if (tier === TIERS.FREE) return; // Individual plan sees the same descriptions regardless of cluster.
      if (isString(tier_descriptions[tier])) {
        returnedDescriptions[tier] = tier_descriptions[tier];
      }
    });
  }
  return returnedDescriptions;
};

export const getValueFeaturesFromClusterConfig = (
  clusterValueFeatures,
  { hasStudentPlan, hasFreeTier, abTests, cookies }
) => {
  if (!clusterValueFeatures || !isObject(clusterValueFeatures)) return;
  return getClusterizedValueFeatures(clusterValueFeatures, { hasStudentPlan, hasFreeTier, abTests, cookies });
};
