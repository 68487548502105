import { FREE_TIER_2021_SEATS_LIMIT } from "constants/pricing";
import * as VALUE_FEATURES from "./value_features";

export const FREE_TIER_VALUE_FEATURES = [
  VALUE_FEATURES.UNLIMITED_BOARDS,
  VALUE_FEATURES.UNLIMITED_DOCS,
  VALUE_FEATURES.OVER_200_TEMPLATES,
  VALUE_FEATURES.COLUMN_TYPES,
  VALUE_FEATURES.UP_TO_X_TEAM_MEMBERS(FREE_TIER_2021_SEATS_LIMIT),
  VALUE_FEATURES.MOBILE
];

export const BASIC_TIER_VALUE_FEATURES = ({ hasFreeTier }) => {
  if (hasFreeTier) {
    return [
      VALUE_FEATURES.UNLIMITED_VIEWERS,
      VALUE_FEATURES.UNLIMITED_ITEMS,
      VALUE_FEATURES.FIVE_GB_STORAGE,
      VALUE_FEATURES.PRIORITISED_CUSTOMER_SUPPORT,
      VALUE_FEATURES.SINGLE_DASHBOARD
    ];
  }
  return [
    VALUE_FEATURES.UNLIMITED_BOARDS,
    VALUE_FEATURES.UNLIMITED_DOCS,
    VALUE_FEATURES.OVER_200_TEMPLATES,
    VALUE_FEATURES.COLUMN_TYPES,
    VALUE_FEATURES.UNLIMITED_VIEWERS,
    VALUE_FEATURES.MOBILE,
    VALUE_FEATURES.SINGLE_DASHBOARD
  ];
};

export const STANDARD_TIER_VALUE_FEATURES = ({ withoutBasic, hasFreeTier }) =>
  withoutBasic
    ? hasFreeTier
      ? [
          VALUE_FEATURES.UNLIMITED_VIEWERS,
          VALUE_FEATURES.UNLIMITED_ITEMS,
          VALUE_FEATURES.PRIORITISED_CUSTOMER_SUPPORT,
          VALUE_FEATURES.TIMELINE_AND_GANTT_VIEWS,
          VALUE_FEATURES.CALENDAR,
          VALUE_FEATURES.GUESTS,
          VALUE_FEATURES.STANDARD_AUTOMATIONS,
          VALUE_FEATURES.STANDARD_INTEGRATIONS,
          VALUE_FEATURES.STANDARD_DASHBOARDS
        ]
      : [
          VALUE_FEATURES.UNLIMITED_BOARDS,
          VALUE_FEATURES.UNLIMITED_DOCS,
          VALUE_FEATURES.UNLIMITED_VIEWERS,
          VALUE_FEATURES.OVER_200_TEMPLATES,
          VALUE_FEATURES.COLUMN_TYPES,
          VALUE_FEATURES.TIMELINE_AND_GANTT_VIEWS,
          VALUE_FEATURES.CALENDAR,
          VALUE_FEATURES.GUESTS,
          VALUE_FEATURES.STANDARD_AUTOMATIONS,
          VALUE_FEATURES.STANDARD_INTEGRATIONS,
          VALUE_FEATURES.STANDARD_DASHBOARDS,
          VALUE_FEATURES.MOBILE
        ]
    : [
        VALUE_FEATURES.TIMELINE_AND_GANTT_VIEWS,
        VALUE_FEATURES.CALENDAR,
        VALUE_FEATURES.GUESTS,
        VALUE_FEATURES.STANDARD_AUTOMATIONS,
        VALUE_FEATURES.STANDARD_INTEGRATIONS,
        VALUE_FEATURES.STANDARD_DASHBOARDS
      ];

export const PRO_TIER_VALUE_FEATURES = [
  VALUE_FEATURES.PRIVATE_BOARDS,
  VALUE_FEATURES.CHART_VIEW,
  VALUE_FEATURES.TIME_TRACKING,
  VALUE_FEATURES.FORMULA,
  VALUE_FEATURES.DEPENDENCY,
  VALUE_FEATURES.PRO_AUTOMATIONS,
  VALUE_FEATURES.PRO_INTEGRATIONS,
  VALUE_FEATURES.PRO_DASHBOARDS
];

export const STUDENT_PLAN_VALUE_FEATURES = [
  VALUE_FEATURES.UNLIMITED_BOARDS,
  VALUE_FEATURES.OVER_200_TEMPLATES,
  VALUE_FEATURES.COLUMN_TYPES,
  VALUE_FEATURES.UNLIMITED_VIEWERS,
  VALUE_FEATURES.MOBILE,
  VALUE_FEATURES.CHART_VIEW
];

export const ENTERPRISE_TIER_VALUE_FEATURES = [
  VALUE_FEATURES.ENTERPRISE_SCALE_AUTOMATIONS,
  VALUE_FEATURES.ENTERPRISE_GRADE_SECURITY,
  VALUE_FEATURES.ADVANCED_REPORTING,
  VALUE_FEATURES.MULTI_LEVEL_PERMISSIONS,
  VALUE_FEATURES.TAILORED_ONBOARDING,
  VALUE_FEATURES.PREMIUM_SUPPORT,
  VALUE_FEATURES.ENTERPRISE_DASHBOARDS
];
