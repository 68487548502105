import { TIERS } from "constants/pricing";
import { isFreeTierNameFree } from "../../../../services/ab-tests/monetization-ab-tests-service";

const DEFAULT_TIER_DESCRIPTIONS = {
  [TIERS.FREE]: "pricingPage.tierDescriptions.individual.new",
  [TIERS.BASIC]: "pricingPage.tierDescriptions.basic.new",
  [TIERS.STANDARD]: "pricingPage.tierDescriptions.standard.new",
  [TIERS.PRO]: "pricingPage.tierDescriptions.pro.new",
  [TIERS.ENTERPRISE]: "pricingPage.tierDescriptions.enterprise.new"
};

export const getDefaultTierDescriptions = ({ hasStudentPlan, cookies, abTests, forcePricing }) => {
  const tierDescriptions = { ...DEFAULT_TIER_DESCRIPTIONS };
  if (hasStudentPlan) {
    tierDescriptions[TIERS.FREE] = "pricingPage.tierDescriptions.student";
  } else if (isFreeTierNameFree({ cookies, abTests, forcePricing })) {
    tierDescriptions[TIERS.FREE] = "pricingPage.tierDescriptions.individual.new";
  }
  return tierDescriptions;
};
