import { TIERS } from "constants/pricing";
import { applyDiscountIfNeeded, applyFreeUsersIfNeeded } from "/services/pricing/discounts-service";
import { shouldShowYearlyPrices } from "/services/pricing/periods-service";

export default class BasePricingModelService {
  getPlanPrice(
    tier,
    currency,
    billingPeriod,
    userCount,
    pricingVersion,
    clusterConfig,
    abTests,
    cookies,
    forcePricing,
    hideDiscount = false
  ) {
    const prices = this.getPrices({ pricingVersion });

    if (tier === TIERS.FREE) return 0;
    const basePrice = applyFreeUsersIfNeeded(
      prices[billingPeriod][tier][currency],
      userCount,
      clusterConfig,
      abTests,
      cookies,
      forcePricing
    );
    const price = shouldShowYearlyPrices(clusterConfig, abTests, cookies, forcePricing) ? basePrice * 12 : basePrice;
    return hideDiscount
      ? price
      : applyDiscountIfNeeded(price, tier, billingPeriod, clusterConfig, abTests, cookies, forcePricing);
  }

  shouldShowUserOptions() {
    return true;
  }

  getDefaultUserOption() {
    return "0";
  }

  getUserOptions(options = {}) {
    return {};
  }

  getPrices() {
    return [];
  }
}
